export const ChevronSharpRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="238"
      height="380"
      viewBox="0 0 238 380"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.858398 48.579L47.9991 1.43835L236.56 190L47.9997 378.56L0.8594 331.42L142.28 190L0.858398 48.579Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
